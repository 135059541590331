import React, {useState} from 'react'
import {Table} from "@material-ui/core";
import {TableBody} from "@material-ui/core";
import {TableCell} from "@material-ui/core";
import {TableContainer} from "@material-ui/core";
import {TableFooter} from "@material-ui/core";
import {TablePagination} from "@material-ui/core";
import {TableRow} from "@material-ui/core";
import {TableHead} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { dateStringConvert } from '../../Utils/ConvertDate';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import { getCustomFilterName, MonthPickerComponent } from './DashboardContent';

export const useMuiPagination = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
     
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
  };

  return {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage}
}

const useTopSellingProductsStyles = makeStyles({
    tableHeadText:{
      fontFamily: 'Inter',
      fontStyle: 'normal',
      padding: '8px',
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: '-0.3px',
      color: '#8a8a8a'
    },
    tableStyledRow : {
      backgroundColor: '#EFF0F6',
     border: 'none'

    },
    tableCellText: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      padding: '8px',
      fontSize: '12px',
      lineHeight: '120%',
      letterSpacing: '-0.4px',
      color: '#171717',
    },
    emptytext: {
      textAlign: 'center'
    },
    tableContainterStyle: {
      border:'none',
    }
})

/**
 * Function renders the  sales report for Today
 * @param {*} param0 
 * @returns 
 */
 export const DashboardReport  = ({reportProps, filter_type, handleFilterChange, filter_names, handleCustomDateChange, custom_filter}) => {
    const {salesAmount, purchaseAmount, total_credit_sales, total_cash_sales, total_paid_purchases, total_credit_purchase} = reportProps;

    return (
        <div>
            <div style={{marginBottom:'5px', textAlign:'right'}}>
                <ButtonGroup  defaultValue={filter_type} >
                    <Button value={'today'} onClick={(e)=>handleFilterChange(e)}
                    style={{background:`${filter_type === 'today' ? `#00a7f0` : `#DCF9FF`}`, color : `${filter_type === 'today' ? 'white' : '#355AA8'}`, 
                    fontSize:'11px', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', border:'none', borderRight: '1px solid #00a0b9'}}>
                        Today
                    </Button>
                    <Button value={'this_week'} onClick={(e)=>handleFilterChange(e)}
                    style={{background:`${filter_type === 'this_week' ? `#00a7f0` : `#DCF9FF`}`, color : `${filter_type === 'this_week' ? 'white' : '#355AA8'}`,
                    fontSize:'11px',border:'none',  borderLeft: '1px solid #00a0b9' , borderRight: '1px solid #00a0b9'}}>
                        This Week 
                    </Button>
                    <Button value={'this_month'} onClick={(e)=>handleFilterChange(e)}
                    style={{background:`${filter_type === 'this_month' ? `#00a7f0` : `#DCF9FF`}`, color : `${filter_type === 'this_month' ? 'white' : '#355AA8'}`,
                        fontSize:'11px', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', border:'none', borderLeft: '1px solid #00a0b9',borderRight: '1px solid #00a0b9'}}>
                        This Month 
                    </Button>
                    
                    {filter_type === 'custom_day' ?
                        <Button
                            style={{background:`${filter_type === 'custom_day' ? `#00a7f0` : `#DCF9FF`}`, color : `${filter_type === 'custom_day' ? 'white' : '#355AA8'}`,
                            fontSize:'11px',borderTopRightRadius: '0px', borderBottomRightRadius: '0px', border:'none', borderLeft: '1px solid #00a0b9', borderRight: '1px solid #00a0b9' }}
                        >
                            <Form.Control style={{background:`#00a7f0`, color : `${filter_type === 'custom_day' ? 'white' : '#355AA8'}`, fontSize:'11px', border:'none', padding:'0px', height:'auto'}}
                            type={'date'}  onChange={(e) => handleCustomDateChange(e)}
                            />
                        </Button>
                    :
                    <Button value={'custom_day'} onClick={(e)=>handleFilterChange(e)}
                        style={{background:`${filter_type === 'custom_day' ? `#00a7f0` : `#DCF9FF`}`, color : `${filter_type === 'custom_day' ? 'white' : '#355AA8'}`,
                        fontSize:'11px',borderTopRightRadius: '0px', borderBottomRightRadius: '0px', border:'none', borderLeft: '1px solid #00a0b9', borderRight: '1px solid #00a0b9' }}>
                        Custom Date
                    </Button>
                    }

                    {filter_type === 'custom_month' ?
                        <Button 
                            style={{background:`${filter_type === 'custom_month' ? `#00a7f0` : `#DCF9FF`}`, color : `${filter_type === 'custom_month' ? 'white' : '#355AA8'}`,
                            fontSize:'11px', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', border:'none', borderLeft: '1px solid #00a0b9', borderRight: '1px solid #00a0b9'}}
                        >
                            {/*<Form.Control style={{background:`#00a7f0`, color : `${filter_type === 'custom_month' ? 'white' : '#355AA8'}`, fontSize:'11px', border:'none', padding:'0px', height:'auto'}}
                            type={'month'} onChange={(e) => handleCustomDateChange(e)}
                            /> */}
                            <MonthPickerComponent  handleChange={handleCustomDateChange} class_name={'dashboard-table-month-picker'} />
                        </Button>
                    :
                    <Button value={'custom_month'} onClick={(e)=>handleFilterChange(e)}
                        style={{background:`${filter_type === 'custom_month' ? `#00a7f0` : `#DCF9FF`}`, color : `${filter_type === 'custom_month' ? 'white' : '#355AA8'}`,
                        fontSize:'11px', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', border:'none', borderLeft: '1px solid #00a0b9', borderRight: '1px solid #00a0b9'}}>
                        Custom Month
                    </Button>
                    }
                </ButtonGroup>
            </div>
            <div style={{marginBottom:'5px'}}>
                {custom_filter ? 
                    <div>
                        <p  className="dashboard-card-font-white">{getCustomFilterName(filter_type, custom_filter)}'s Report</p>                       
                    </div>
                    :
                    <p className="dashboard-card-font-white">{`${filter_names[filter_type]}`}'s Report</p> 
                }
                <div className="white-short-border"></div>
            </div>
            <div className="dashboard-report-table">
                <div className="table-responsive">
                    <table className="table table-borderless table-sm ">
                        <thead>
                            <tr className="table-border" style={{color:'white'}}>
                                <th/>
                                <th style={{fontWeight: '800', fontSize: '14px'}}>Cash Amount</th>
                                {/* <th style={{fontWeight: '800', fontSize: '14px'}}>Credit Amount</th> */}
                                <th style={{fontWeight: '800', fontSize: '14px'}}>Total Amount</th>
                            </tr>
                        </thead>
                        <tbody style={{color:'white'}}>
                            <tr>
                                <td style={{fontWeight: '800', fontSize: '12px'}}>Total Sales</td>
                                <td>{total_cash_sales.toLocaleString()}</td>
                                {/* <td>{total_credit_sales.toLocaleString()}</td> */}
                                <td>{salesAmount.toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: '800', fontSize: '12px'}}>Total Purchase</td>
                                <td>{total_paid_purchases.toLocaleString()}</td>
                                {/* <td>{total_credit_purchase.toLocaleString()}</td> */}
                                <td>{purchaseAmount.toLocaleString()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

/**
 * Function that renders the table for the Top Selling Products
 * @param {*} param0 
 * @returns 
 */
export const TopSellingProducts = ({topSellingProducts, filter_type, filter_names, custom_filter}) => {

    const class_styles = useTopSellingProductsStyles();
    const {page, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();

    return (
        <div data-testid="top-selling-products-dashboard">
            <div style={{display:'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                <div>
                <p className="dashboard-card-font-color">Top Selling Products (According to Revenue)</p>
                <div className="orange-short-border"></div>
                </div>
                {/*<div style={{marginBottom:'5px'}}>
                    <ButtonGroup  defaultValue={filter_type} >
                        <Button id="tbg-btn-1" value={'today'} onClick={(e)=>handleFilterChange(e)}
                        style={{background:`${filter_type === 'today' ? `#00a7f0` : `#DCF9FF`}`, color : `${filter_type === 'Today' ? 'white' : '#355AA8'}`, 
                        fontSize:'11px', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', border:'none', borderRight: '1px solid #00a0b9'}}>
                            Today
                        </Button>
                        <Button id="tbg-btn-2" value={'this_week'} onClick={(e)=>handleFilterChange(e)}
                        style={{background:`${filter_type === 'this_week' ? `#00a7f0` : `#DCF9FF`}`, color : `${filter_type === 'This Week' ? 'white' : '#355AA8'}`,
                        fontSize:'11px',border:'none',  borderLeft: '1px solid #00a0b9' , borderRight: '1px solid #00a0b9'}}>
                            Week 
                        </Button>
                        <Button id="tbg-btn-3" value={'this_month'} onClick={(e)=>handleFilterChange(e)}
                        style={{background:`${filter_type === 'this_month' ? `#00a7f0` : `#DCF9FF`}`, color : `${filter_type === 'This Month' ? 'white' : '#355AA8'}`,
                            fontSize:'11px', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', border:'none', borderLeft: '1px solid #00a0b9'}}>
                            Month 
                        </Button>
                    </ButtonGroup>
                </div>*/}
                {custom_filter ? 
                    <div>
                        <p  className="dropdown-filter">{getCustomFilterName(filter_type, custom_filter)}</p>                       
                    </div>
                    :
                    <div>
                        <p  className="dropdown-filter">{filter_names[filter_type]}</p>                       
                    </div> 
                }
            </div>
            <div>
                <TableContainer className="w-100" classes={{root:class_styles.tableContainterStyle}} >
                    <Table aria-label="custom pagination table">
                        <TableHead>
                        <TableRow classes={{root:class_styles.tableStyledRow}}>
                            <TableCell className={class_styles.tableHeadText}>Name</TableCell>
                            <TableCell className={class_styles.tableHeadText}>Quantity</TableCell>
                            <TableCell className={class_styles.tableHeadText}>Revenue</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {topSellingProducts.length > 0 ?
                                topSellingProducts.slice(page * 5, page * 5 + 5).map((product, index) => {
                                    return (
                                    <TableRow key={index}>
                                        <TableCell className={class_styles.tableCellText}>
                                        <p style={{margin:'4px', fontSize:'12px'}}>{product.product_name}</p>
                                        </TableCell>
                                        <TableCell className={class_styles.tableCellText}>
                                        <p style={{margin:'4px', fontSize:'12px'}}>{product.total_quantity_sold.toLocaleString()}</p>
                                        </TableCell>
                                        <TableCell className={class_styles.tableCellText}>
                                        <p style={{margin:'4px', fontSize:'12px'}}>{product.total_revenue_generated.toLocaleString()}</p>
                                        </TableCell>
                                    </TableRow>
                                    );
                            })
                            :
                            <TableRow>
                                <TableCell colSpan={3} className={class_styles.emptytext}>
                                <p style={{margin:'6px'}}>No top selling products found</p>
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                        <TableFooter>
                        <TableRow>
                            <TablePagination
                            rowsPerPageOptions={[5]}
                            colSpan = {3}
                            count = {topSellingProducts.length}
                            page = {page}
                            rowsPerPage = {5}
                            onPageChange = {handleChangePage}
                            onRowsPerPageChange = {handleChangeRowsPerPage}
                            />
                        </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

/**
 * Function to render the table for cash payments on the dashboard
 * @param {*} param0 
 * @returns 
 */
export const CashPaymentsDashboardTable = ({ cashPayments, filter_type, filter_names, custom_filter}) => {
  const class_styles = useTopSellingProductsStyles();
  const {page, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();

  return (
    <div data-testid="dashboard-cash-payments">
        <div style={{display:'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
            <div>
                <p className="dashboard-card-font-color">Cash Payments</p>
                <div className="orange-short-border"></div>
            </div>
            {custom_filter ? 
                <div>
                    <p  className="dropdown-filter">{getCustomFilterName(filter_type, custom_filter)}</p>                       
                </div>
                :
                <div>
                    <p  className="dropdown-filter">{filter_names[filter_type]}</p>                       
                </div> 
            }
        </div>
        <div>
            <TableContainer className="w-100" classes={{root:class_styles.tableContainterStyle}} >
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow classes={{root:class_styles.tableStyledRow}}>
                        <TableCell className={class_styles.tableHeadText}>Date Paid</TableCell>
                        <TableCell className={class_styles.tableHeadText}>Receipt Number</TableCell>
                        <TableCell className={class_styles.tableHeadText}>Customer Name</TableCell>
                        <TableCell className={class_styles.tableHeadText}>Amount Paid</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {cashPayments.length > 0 ?
                        cashPayments.slice(page * 5, page * 5 + 5).map((product, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell className={class_styles.tableCellText}>
                                        <p style={{margin:'4px', fontSize:'12px'}}>{dateStringConvert(product.date_paid)}</p>
                                    </TableCell>
                                    <TableCell className={class_styles.tableCellText}>
                                        <p style={{margin:'4px', fontSize:'12px'}}>{product.receipt_number}</p>
                                    </TableCell>
                                    <TableCell className={class_styles.tableCellText}>
                                        <p style={{margin:'4px', fontSize:'12px'}}>{product.customer_name}</p>
                                    </TableCell>
                                    <TableCell className={class_styles.tableCellText}>
                                        <p style={{margin:'4px', fontSize:'12px'}}>{product.amount_paid}</p>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    :
                        <TableRow>
                            <TableCell colSpan={4} className={class_styles.emptytext}>
                                <p style={{margin:'6px'}}>No cash payments found</p>
                            </TableCell>
                        </TableRow>
                    }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5]}
                            colSpan = {4}
                            count = {cashPayments.length}
                            page = {page}
                            rowsPerPage = {5}
                            onPageChange = {handleChangePage}
                            onRowsPerPageChange = {handleChangeRowsPerPage}
                        />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    </div>
  )
}

/**
 * Function that renders the debtors table for the dashboard
 * @param {*} param0 
 * @returns 
 */
export const DebtorsDashboardTable = ({ debtors, loading, filter_type, filter_names, custom_filter }) => {

  const {page, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
  const class_styles = useTopSellingProductsStyles();

  return (
    <div data-testid="dashboard-debtors-table">
        <div style={{display:'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
            <div>
                <p className="dashboard-card-font-color">Debtors</p>
                <div className="orange-short-border"></div>
            </div>
            {custom_filter ? 
            <div>
                <p  className="dropdown-filter">{getCustomFilterName(filter_type, custom_filter)}</p>                       
            </div>
            :
            <div>
                <p  className="dropdown-filter">{filter_names[filter_type]}</p>                       
            </div> 
            }
        </div>
        <TableContainer>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow classes={{root:class_styles.tableStyledRow}}>
                            <TableCell className={class_styles.tableHeadText}>Customer Name</TableCell>
                            <TableCell className={class_styles.tableHeadText}>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    {loading ? 
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan="2" align="center"><img src="/images/listPreloader.gif" alt="loader" style={{width:'10%'}}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    :
                    debtors.length > 0 ?
                        <TableBody>
                            {debtors.slice(page * 5, page * 5 + 5)
                                .map((item) => {
                                    return (
                                        <TableRow>
                                            <TableCell className={class_styles.tableCellText}>
                                                <p style={{margin:'4px', fontSize:'12px'}}>{item.name}</p>
                                            </TableCell>
                                            <TableCell className={class_styles.tableCellText}>
                                                <p style={{margin:'4px', fontSize:'12px'}}>{item.amount}</p>
                                            </TableCell>
                                        </TableRow>
                                    );
                            })}
                        </TableBody>
                        : 
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan="2" align="center">No records found</TableCell>
                            </TableRow>
                        </TableBody>}
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5]}
                            colSpan = {2}
                            count = {debtors.length}
                            page = {page}
                            rowsPerPage = {5}
                            onChangePage = {handleChangePage}
                            onChangeRowsPerPage = {handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
                </Table>
        </TableContainer>
    </div>
  )
}