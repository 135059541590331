import { useRef, useState } from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { useEffect } from "react";
import { history } from "../../../Helpers/history";
import { useAddMedicineType } from "./useAddType";
import { useAddUnit } from "./useAddUnit";
import { useAddCategory } from "./useAddCategory";
import { titleCase } from "../../../Utils/titleCaseFunction";

const createOption = (label) => ({
  label,
  value: label,
});

const defaultOptions = [
  createOption("10"),
  createOption("20"),
  createOption("30"),
  createOption("40"),
  createOption("50"),
  createOption("60"),
  createOption("70"),
  createOption("80"),
  createOption("90"),
  createOption("100"),
];
export const useAddMedicine = (
  actions,
  product_id = null,
  isPurchase = false
) => {
  const [state, setState] = useState({
    product_name: "",
    strength: "",
    generic_name: "",
    actual_price: "",
    box_size: "",
    product_location: "",
    description: "",
    price_type: "",
    order_level: 0,
    expire_limit: 0,
    max_stock: 0, // zero for maximum stock implies no max stock
    expiry_days: 10,
  });
  const storage = JSON.parse(sessionStorage.getItem("products"));
  const item = storage ? storage : [];
  const products = () => item || [];

  const showForm = products().length <= 0;

  const [unit, setUnit] = useState(null);
  const [type_name, setTypeName] = useState(null);
  const [category_id, setCategoryId] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState("idle");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [price, setPrice] = useState(null);
  const [product, setProduct] = useState({});
  const [categoryList, setCategories] = useState([]);
  const [unitList, setUnits] = useState([]);
  const [typeList, setTypes] = useState([]);
  const [openProductDialog, setOpenProductDialog] = useState(false);
  const [addedProducts, setAddedProducts] = useState(products());
  const [isShowForm, setIsShow] = useState(showForm);
  // csv props
  const [allProducts, setAllProducts] = useState([]);
  const [csvdialog, setCSVDialog] = useState(false);
  const [productCsv, setproductCsv] = useState(null);
  const [error_message, setErrorMessage] = useState("");
  const [isBlocking, setIsBlocking] = useState(false);
  const csvLink = useRef();

  const formRef = useRef();
  const { isResolved: typeResolved, typeProps } = useAddMedicineType(actions);
  const { isResolved: unitResolved, unitProps } = useAddUnit(actions);
  const { isResolved: categoryResolved, categoryProps } =
    useAddCategory(actions);

  useEffect(() => {
    axios
      .get(`${config.epharmUrl}/Cproduct/unit_list`)
      .then((res) => {
        const data = res.data;
        const dt = data ? data : {};
        const units = dt.unit ? dt.unit : [];
        setUnits(units);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  }, [unitResolved]);

  useEffect(() => {
    axios
      .get(`${config.epharmUrl}/Ccategory/manage_category`)
      .then((res) => {
        const data = res.data;
        const dt = data ? data : {};
        const categories = dt.category_list ? dt.category_list : [];
        setCategories(categories);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  }, [categoryResolved]);

  useEffect(() => {
    axios
      .get(`${config.epharmUrl}/Cproduct/typeindex`)
      .then((res) => {
        const data = res.data;
        const dt = data ? data : {};
        const types = dt.type_list ? dt.type_list : [];
        setTypes(types);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  }, [typeResolved]);

  useEffect(() => {
    if (!product_id) {
      return;
    }
    axios
      .get(`${config.epharmUrl}/Cproduct/product_update_form`, {
        params: { product_id },
      })
      .then((response) => {
        const data = response.data;
        const dt = data ? data : {};
        setProduct(dt);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  }, []);

  useEffect(() => {
    if (!product_id) {
      return;
    }
    const productDetails = !product ? {} : product;

    const product_name = !productDetails.product_name
      ? ""
      : productDetails.product_name;
    const generic_name = !productDetails.generic_name
      ? ""
      : productDetails.generic_name;
    const box_size = !productDetails.box_size ? "" : productDetails.box_size;
    const strength = !productDetails.strength ? "" : productDetails.strength;
    const product_location = !productDetails.product_location
      ? ""
      : productDetails.product_location;
    const product_details = !productDetails.product_details
      ? ""
      : productDetails.product_details;
    const price = !productDetails.price ? 0 : productDetails.price;
    const price_type = !productDetails.price_type
      ? 0
      : productDetails.price_type;
    const reorder_level = !productDetails.reorder_level
      ? 0
      : productDetails.reorder_level;
    const expr_lmt = !productDetails["expiry_limit"]
      ? 0
      : productDetails["expiry_limit"];

    const max_stck = !productDetails["maximum_stock"]
      ? 0
      : productDetails["maximum_stock"];

    setState({
      product_name,
      strength,
      generic_name,
      actual_price: price_type === 0 ? price : "",
      box_size,
      product_location,
      description: product_details,
      price_type: String(price_type),
      order_level: reorder_level,
      expire_limit: expr_lmt,
      max_stock: max_stck,
      expiry_days: state.expiry_days,
    });
    setCategoryId({
      category_id: !productDetails.category_id
        ? ""
        : productDetails.category_id,
      category_name: !productDetails.category_name
        ? "Category not selected"
        : productDetails.category_name,
    });
    setTypeName({
      type_id: productDetails.product_model,
      type_name: productDetails.product_model,
    });
    setUnit({ id: productDetails.unit, unit_name: productDetails.unit });
    setPrice({
      value: price_type === 1 ? price : "",
      label: price_type === 1 ? price : "",
    });
  }, [product, product_id]);

  const handleOpenProductDialog = () => {
    setOpenProductDialog(true);
  };

  const handleCloseProductDialog = () => {
    setOpenProductDialog(false);
  };

  const handleChangeFields = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
    setIsBlocking(true);
  };

  const handleChangeCategory = (value) => {
    setCategoryId(value);
    setIsBlocking(true);
  };

  const handleChangeType = (value) => {
    setTypeName(value);
    setIsBlocking(true);
  };

  const handleChangeUnit = (value) => {
    setUnit(value);
    setIsBlocking(true);
  };

  const handleInputChange = (inputValue) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptions([...options, newOption]);
      setPrice(newOption);
    }, 1000);
    setIsBlocking(true);
  };

  const handleChange = (newValue) => {
    setPrice(newValue);
    setIsBlocking(true);
  };

  const addAnotherProduct = () => {
    setIsShow(true);
  };

  const cancelAdd = () => {
    const show = addedProducts.length <= 0;
    setIsShow(show);
  };

  const addProduct = () => {
    const {
      product_name,
      generic_name,
      actual_price,
      price_type,
      order_level,
      expire_limit,
      box_size,
      product_location,
      max_stock,
      strength,
      description,
      expiry_days,
    } = state;
    const isActual = price_type === "0" && actual_price;
    const markupObj = price ? price : {};
    const markup_value = markupObj.value ? markupObj.value : 0;
    const isMarkup = price_type === "1" && markup_value;
    const medicine_unit = unit ? unit : {};
    const unit_label = medicine_unit.unit_name ? medicine_unit.unit_name : "";
    const pr = price_type === "1" ? markup_value : actual_price;
    setSubmitted(true);
    if (product_name && category_id && price_type && (isActual || isMarkup)) {
      const productObj = {
        unit: unit_label,
        product_model: type_name ? type_name.type_name : "",
        category_id: +category_id.category_id ?? "",
        category_name: category_id.category_name
          ? category_id.category_name
          : "",
        price: +pr,
        tax0: 0,
        tax1: 0,
        product_name,
        generic_name,
        strength,
        reorder_level: order_level,
        expiry_limit: expire_limit,
        box_size,
        product_location,
        maximum_stock: max_stock,
        price_type,
        description,
        expiry_days,
      };
      const arr = [...item, productObj];
      sessionStorage.setItem("products", JSON.stringify(arr));
      setAddedProducts(arr);
      setIsShow(false);
      setSubmitted(false);
      setState({
        product_name: "",
        strength: "",
        generic_name: "",
        actual_price: "",
        box_size: "",
        product_location: "",
        description: "",
        price_type: "",
        order_level: 0,
        expire_limit: 0,
        max_stock: 0,
        expiry_days: 10,
      });
      setTypeName("");
      setUnit("");
      setCategoryId("");
      setPrice("");
    }
    setIsBlocking(true);

    console.log("Days to expire", expiry_days);
  };

  const handleRemoveProduct = (idx) => {
    const arr = addedProducts.filter((_, index) => idx !== index);
    sessionStorage.setItem("products", JSON.stringify(arr));
    setAddedProducts(arr);
    if (arr.length === 0) {
      setIsShow(true);
    }
    setIsBlocking(true);
  };

  const postData = (jsonData) => {
    setIsBlocking(false);
    const url = product_id ? "product_update" : "insert_product";
    axios
      .post(`${config.epharmUrl}/Cproduct/${url}`, jsonData)
      .then((res) => {
        actions.snackbarActions.successSnackbar("Product Saved Successfully");
        setIsSubmitted("resolved");
        setState({
          product_name: "",
          strength: "",
          generic_name: "",
          actual_price: "",
          box_size: "",
          product_location: "",
          description: "",
          price_type: "",
          reorder_level: 10,
          expiry_limit: 0,
          maximum_stock: 0,
          expiry_days: 10,
        });
        setTypeName("");
        setUnit("");
        setCategoryId("");
        setPrice("");
        setSubmitted(false);
        if (isPurchase) {
          setOpenProductDialog(false);
        } else {
          history.push("/managemedicine");
        }
        sessionStorage.removeItem("products");
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setIsSubmitted("rejected");
      });
  };

  const handleSaveSingleProduct = (event) => {
    event.preventDefault();
    const {
      product_name,
      generic_name,
      actual_price,
      price_type,
      order_level,
      expire_limit,
      box_size,
      product_location,
      max_stock,
      strength,
      expiry_days,
    } = state;
    setSubmitted(true);
    const isActual = price_type === "0" && actual_price;
    const markupObj = price ? price : {};
    const markup_value = markupObj.value ? markupObj.value : 0;
    const isMarkup = price_type === "1" && markup_value;
    const medicine_unit = unit ? unit : {};
    const unit_label = medicine_unit.unit_name ? medicine_unit.unit_name : "";
    const med_type = type_name ? type_name : {};
    const category = category_id ? category_id : {};

    if (product_name && category_id && price_type && (isActual || isMarkup)) {
      const pr = price_type === "1" ? markup_value : actual_price;

      setIsSubmitted("pending");

      const updateData = product_id ? { product_id } : {};
      const json = {
        unit: unit_label,
        product_model: type_name ? type_name.type_name : "",
        category_id: category.category_id ? +category.category_id : "",
        price: +pr,
        tax0: 0,
        tax1: 0,
        product_name,
        generic_name,
        strength,
        reorder_level: order_level,
        expiry_limit: expire_limit,
        box_size,
        product_location,
        maximum_stock: max_stock,
        price_type,
        expiry_days,
        ...updateData,
      };

      const jsonData = product_id ? json : [json];

      postData(jsonData);
    }
  };

  const handleSaveMedicine = (event) => {
    event.preventDefault();

    setSubmitted(true);
    setIsSubmitted("pending");

    const updateData = product_id ? { product_id } : {};
    const jsonData = addedProducts.map((item) => ({
      ...item,
      ...updateData,
    }));
    postData(jsonData);
  };

  const handleCloseSnackbar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const isPending = isSubmitted === "pending";

  const handleOpenCSVDialog = () => {
    setCSVDialog(true);
  };

  const handleCloseCSVDialog = () => {
    setCSVDialog(false);
    setErrorMessage("");
  };

  const handleUploadCsv = (event) => {
    event.preventDefault();
    if (productCsv) {
      const formData = new FormData();
      formData.append("upload_csv_file", productCsv ? productCsv : "");
      axios
        .post(`${config.epharmUrl}/Cproduct/uploadCsv_product`, formData)
        .then((res) => {
          actions.snackbarActions.successSnackbar(
            "Product csv uploaded Successfully"
          );
          //const data_arr = res.data?? [];
          if (error_message) setErrorMessage("");
          setTimeout(() => {
            history.push("/managemedicine");
          }, 1000);
        })
        .catch((error) => {
          const err_msg =
            error.response && error.response.data
              ? error.response.data.message.split("-")[1]
              : "";
          setErrorMessage("Missing field in file: " + err_msg);
        });
    } else {
      setErrorMessage("Please select a csv or excel file to upload");
    }
  };

  const handleChangeProductCsv = (event) => {
    const file_extension = event.target.files[0].name.substr(
      event.target.files[0].name.length - 4
    );
    if (
      file_extension === ".csv" ||
      file_extension === ".xls" ||
      file_extension === "xlsx"
    ) {
      setproductCsv(event.target.files[0]);
      if (error_message) setErrorMessage("");
    } else {
      event.target.value = null;
      setErrorMessage("Please upload only csv or excel (.xls or .xlsx) format");
    }
  };

  const fetchData = () => {
    axios
      .get(`${config.epharmUrl}/Cproduct/product_csv`, {
        params: { download: true },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Product_Template.csv`);
        document.body.appendChild(link);
        link.click();
        setErrorMessage("");
      })
      .catch((err) => {
        errorMessages(err, null, actions);
      });
  };

  const productProps = {
    state,
    unit,
    type_name,
    category_id,
    submitted,
    isSubmitted,
    isLoading,
    options,
    price,
    formRef,
    handleChangeFields,
    handleChangeCategory,
    handleChangeType,
    handleChangeUnit,
    handleInputChange,
    handleChange,
    isPending,
    handleSaveMedicine,
    handleCloseSnackbar,
    categoryList,
    unitList,
    typeList,
    addedProducts,
    addProduct,
    addAnotherProduct,
    isShowForm,
    handleRemoveProduct,
    cancelAdd,
    handleSaveSingleProduct,
    unitProps,
    categoryProps,
    typeProps,
  };

  const csvDialogProps = {
    handleChangeProductCsv,
    handleUploadCsv,
    csvdialog,
    handleOpenCSVDialog,
    handleCloseCSVDialog,
    fetchData,
    allProducts,
    csvLink,
    error_message,
  };

  return {
    productProps,
    handleCloseSnackbar,
    openProductDialog,
    handleCloseProductDialog,
    handleOpenProductDialog,
    unitProps,
    categoryProps,
    typeProps,
    csvDialogProps,
    isBlocking,
  };
};
